import './App.css';
import Homepage from './components/Homepage';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import ReactGA from 'react-ga4';  // Import react-ga4
import DashboardHome from './components/DashboardHome';
import PrivateRoute from './components/PrivateRoute';

function AppRoutes() {
  const routes = [
    { path: "/", element: <Homepage /> },
    { path: "/dashboard", element: <PrivateRoute element={<DashboardHome />} /> },
  ];

  let element = useRoutes(routes);
  return element;
}

function App() {
  useEffect(() => {
    // Initialize GA4 with your Measurement ID (G-XXXXXXX)
    ReactGA.initialize(process.env.REACT_APP_FB_measurementId); // Replace with your GA4 Measurement ID
    ReactGA.send('pageview');
  }, []);

  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

export default App;
